import React from 'react'
import bcutout from '../assets/imgs/dragon-cutsy-logow.svg'
import { motion } from 'framer-motion';

export const Loadscreen = ({ loading }) => {
    return (

        loading &&
        <div className='loadingscreen'>
            <motion.img src={bcutout} alt="cutout" className='cutout'
                initial={{ scale: 1, opacity: 1 }}
                animate={{ scale: 20, opacity: 0 }}
                transition={{
                    scale: { delay: 5, duration: 3, ease: "linear" },
                    opacity: { delay: 5, duration: 0.5 }
                }}
            />
        </div>


    )
}
