import React from 'react'
import { motion } from 'framer-motion';
import { FaDiscord, FaDragon, FaTelegram, FaXTwitter } from "react-icons/fa6";
import { IoMegaphone } from "react-icons/io5";
import toppic from '../assets/imgs/ndragon-toppng.png'
import head from '../assets/imgs/3d-dragon-head.png'
import sidebar from '../assets/imgs/side-bar.png'
import line from '../assets/imgs/01line.png'
import { GiHammerNails } from "react-icons/gi";
import dhead from '../assets/imgs/dragon-head.png'
import dfoot from '../assets/imgs/dragon-footer.png'


import p1 from '../assets/imgs/dragons/4.webp';
import p2 from '../assets/imgs/dragons/8.png';
import p3 from '../assets/imgs/dragons/11.webp';
import p4 from '../assets/imgs/dragons/10.png';
import p5 from '../assets/imgs/dragons/5.png';
import p6 from '../assets/imgs/dragons/15.webp';
import p7 from '../assets/imgs/dragons/2.png';
import p8 from '../assets/imgs/dragons/9.png';
import p9 from '../assets/imgs/dragons/14.webp';
import p10 from '../assets/imgs/dragons/1.png';
import p11 from '../assets/imgs/dragons/6.png';

const slidingTextVariants = {
    hidden: { x: '-450%' },
    visible: {
        x: '0%',
        transition: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 60,
            ease: "linear"
        }
    }
};

const slidingImgVariants = {
    hidden: { x: '40%' },
    visible: {
        x: '-50%',
        transition: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 300,
            ease: "linear"
        }
    }
};



const bounceVariants = {
    animate: {
        scale: [1, 0.95, 1],
        transition: {
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror",
        },
    },
};


const dragonList = [
    {
        id: "1",
        img: p1,
    },
    {
        id: "2",
        img: p2,
    }, {
        id: "3",
        img: p3,
    }, {
        id: "4",
        img: p4,
    },
    {
        id: "5",
        img: p5,
    },
    {
        id: "6",
        img: p6,
    }, {
        id: "7",
        img: p7,
    }, {
        id: "8",
        img: p8,
    },
    {
        id: "9",
        img: p9,
    },
    {
        id: "10",
        img: p10,
    },
    {
        id: "11",
        img: p11,
    }
]

export const Toparea = () => {
    return (
        <div className='toparea'>

            <div className="topslider ot">
                <motion.div
                    variants={slidingTextVariants}
                    initial="hidden"
                    animate="visible"
                    className='txtcnt'
                >
                    {Array.from({ length: 3 }).map((_, index) => (
                        <React.Fragment key={index}>

                            we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon />
                        </React.Fragment>
                    ))}

                </motion.div>
            </div>

            <div className="redarea">
                <img src={line} alt="line" className='line' />
                <div className="topcent">
                    <img src={toppic} alt="toppic" className='toppic' />
                    <div className="head-imgcnt">
                        <motion.img src={head} alt="head" className='head'
                            whileHover={{ scale: 0.95 }} variants={bounceVariants}
                            animate="animate"

                        />


                    </div>
                </div>
                <img src={sidebar} alt="sidebar" className='sidebar' />
            </div>




            <div className="whatisarea">
                <div className="top">
                    <div className="left"><div className="title ma">
                        What <span className="red">is</span> drag<span className="red">on</span>-kin?
                    </div>
                        <div className="content ot">Year Of The Dragon <span className="red">[Y.O.T.D]</span> offers you an adventure in a blockchain realm where dragons reign supreme. Each player begins with a one-of-a-kind dragon NFT, raising it to harness elemental strengths for strategic battles. <br></br><br></br> The game features a dynamic community-driven experience with opportunities for dragon breeding, discovering rare traits, and engaging in player vs. player combat. Earn rewards through gameplay and shape the game's future with your involvement in governance, all within a sustainable and fair Play & Earn system.
                            <br></br><br></br>
                            <span className="red">[the main site is still work-in-progress and it will go in-depth about Y.O.T.D]</span>
                        </div></div>

                    <div className="right">
                        <motion.div className="contents" variants={slidingImgVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                            {dragonList.map(({ id, img }) =>
                            (
                                <div className="img-cnt" key={id}>
                                    <img src={img} alt="img" className='dragonimg' />
                                </div>
                            ))}
                        </motion.div>
                        <div className="title hyp">[Work-in-progress]</div>

                    </div>

                </div>
                <a href="https://x.com/yotdeth">
                    <div className="bottombtn ot">
                        Join us on Twitter <FaXTwitter />
                    </div>
                </a>


            </div>







            <div className="endarea">

                <div className="topslider ot slidred">
                    <motion.div
                        variants={slidingTextVariants}
                        initial="hidden"
                        animate="visible"
                        className='txtcnt'
                    >
                        {Array.from({ length: 3 }).map((_, index) => (
                            <React.Fragment key={index}>

                                we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon /> we wish you a happy year of the dragon <FaDragon /> the main site is being worked on and will be updated soon <FaDragon /> we are glad to have you here with us <FaDragon />
                            </React.Fragment>
                        ))}
                    </motion.div>
                </div>

                <div className="redfooter">
                    <div className="txt ot">please note: the main site is work-in-progress and it will be updated soon <GiHammerNails /></div>
                    <div className="links">
                        <a href="https://x.com/yotdeth"><FaXTwitter className='icon' /></a>
                        <a href="https://t.me/YOTDPortal"><FaTelegram className='icon' /></a>
                        <a href="https://t.me/YOTDAnnouncements"><IoMegaphone className='icon' /></a>
                        <a href="https://discord.gg/sB8TyUef"><FaDiscord className='icon' /></a>
                    </div>
                    <img src={dhead} alt="dhead" className='dhead' />
                </div>
            </div>

            <div className="footer ot">
                <div className="left">
                    <div className="l1"><span className="red">Year Of The Dragon -</span> the blockchain game</div>
                    <div className="l2">wishing you a happy new <span className="red">dragon year</span></div>
                    <div className="l3">@2024 - All Rights Reserved</div>
                </div>
                <div className="right">
                    <img src={dfoot} alt={dfoot} className='dfoot' />
                </div>
            </div>


        </div>
    )
}
